import React, { CSSProperties, ForwardRefRenderFunction, useEffect, useMemo, useState } from 'react';

import { useSpace } from '../../../providers/space';
import { generateRandomString } from '../../../utils';
import { UiSpace } from '../../space';
import { UiSwitch, UiSwitchProps } from '../UiSwitch';

export type UiSwitchGroupOptionIdType = string;
export type UiSwitchGroupValueType =
  | UiSwitchGroupOptionIdType
  | number
  | Omit<UiSwitchProps, 'checked' | 'defaultValue'>;
export interface UiSwitchGroupOptionValue extends Omit<UiSwitchProps, 'id'> {
  id: UiSwitchGroupOptionIdType;
}
export type UiSwitchGroupProps = {
  disabled?: boolean;
  value?: UiSwitchGroupOptionIdType[];
  defaultValue?: UiSwitchGroupOptionIdType[];
  onChange?: (value: UiSwitchGroupOptionIdType[]) => void;
  options: UiSwitchGroupValueType[];
  style?: CSSProperties;
  className?: string;
  // children?: ReactNode; // TODO поддержать в будущем c переданными <UiSwitch />
};

const SwitchGroup: ForwardRefRenderFunction<HTMLDivElement, UiSwitchGroupProps> = (props, ref) => {
  const { style, className, options: propsOptions, defaultValue, onChange, ...restProps } = props;

  const [value, setValue] = useState<UiSwitchGroupOptionIdType[] | undefined>(
    restProps.value || defaultValue,
  );

  useEffect(() => {
    if ('value' in restProps) {
      setValue(restProps.value);
    }

    if (!restProps.value && defaultValue) {
      setValue(defaultValue);
    }
  }, [restProps.value, defaultValue]);

  const { spaceM } = useSpace();

  const options: UiSwitchGroupOptionValue[] = useMemo(
    () =>
      propsOptions?.map((option) => {
        if (typeof option === 'string' || typeof option === 'number') {
          return { id: option.toString(), label: option.toString() };
        }

        if (!option.id) {
          return { ...option, id: generateRandomString() };
        }

        return option as UiSwitchGroupOptionValue;
      }),
    [propsOptions],
  );

  const toggleOption = (_: boolean, option: UiSwitchGroupOptionValue) => {
    const newValue = [...(value || [])];
    const optionIndex = newValue.indexOf(option.id);

    if (optionIndex === -1) {
      newValue.push(option.id);
    } else {
      newValue.splice(optionIndex, 1);
    }

    setValue(newValue);
    onChange?.(newValue);
  };

  const children = options.map((option) => {
    const optionProps = {
      ...option,
      disabled: 'disabled' in option ? option.disabled : restProps.disabled,
    };

    return (
      <UiSwitch
        {...optionProps}
        key={option.id}
        checked={value ? value.includes(option.id) : option.defaultChecked}
        onChange={(checked) => toggleOption(checked, optionProps)}
      />
    );
  });

  return (
    <div className={className} style={style} ref={ref}>
      <UiSpace size={spaceM} direction="vertical">
        {children}
      </UiSpace>
    </div>
  );
};

export const UiSwitchGroup = React.forwardRef<HTMLDivElement, UiSwitchGroupProps>(SwitchGroup);
