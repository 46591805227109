import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import ru from 'react-phone-input-2/lang/ru.json';

import 'react-phone-input-2/lib/bootstrap.css';
import styles from './UiPhoneInput.scss';

interface UiPhoneProps extends PhoneInputProps {
  className?: string;
}

export const UiPhoneInput: FC<UiPhoneProps> = (props) => {
  const { country = 'ru', placeholder = '+7 ___ ___ __ __', className, value, ...rest } = props;
  const normalizeValue = useMemo(() => (value === null ? undefined : value), [value]);

  return (
    <PhoneInput
      defaultMask="... ... .. .."
      country={country}
      containerClass={classNames(styles.uiPhoneInput, className)}
      localization={ru}
      placeholder={placeholder}
      value={normalizeValue}
      {...rest}
    />
  );
};
